import {Link, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import List from "./List";
import general from "../../general";
import {NavLink} from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import { useScroll } from "ahooks";
import useActiveState from "../../lib/useActiveState";

const menus = [
  // {
  //   id: 'recovery',
  //   text: '小号回收',
  //   url: '/recovery',
  //   user: true,
  // },
  {
    id: 'submit',
    text: '我要卖号',
    url: '/trade/submit',
    user: true,
  },
  {
    id: 'recovery',
    text: '小号回收',
    url: '/recovery',
    user: true,
  },
  {
    id: 'record',
    text: '交易记录',
    url: '/trade/record',
    user: true,
  },
  {
    id: 'notice',
    text: '交易须知',
    url: '/trade/notice',
    user: true,
  },
  // {
  //   id: 'service',
  //   text: '在线客服',
  //   url: '/service',
  // }
];

export default props => {
  const {aplus_queue} = window;
  useEffect(() => {
    // props.setOperate(<Link to="/trade/notice" className="trade-index-notice">交易须知</Link>);
  }, []);
  const history = useHistory();
  const [trades, setTrades] = useState([]);
  const [opacity, setOpacity] = useState();
  const [titleInfo, setTitleInfo] = useState([]);
  const [user] = useActiveState('user');
  const [init, setInit] = useState();
  const [agent] = useActiveState('agent');
  const [index, setIndex] = useState(0);
  const [title_items, setTitle_items] = useState([]);
  
  // const load = () => {
  //   general.axios.post('/user/trade/getTradeList', {
  //     member_id: undefined,
  //     username: undefined,
  //     game_device_type: 2,
  //   })
  //     .finally(() => {
  //       Dialog.close();
  //     })
  //     .then(response => {
  //       let {status, data} = response.data;
  //       if (!status.succeed) {
  //         Dialog.error(status.error_desc);
  //         return;
  //       }
  //       setInit(true);
  //       setTrades(data.featured_list);
  //       setTitleInfo(data.title_info);
  //     })
  //     .catch(err => {
  //         Dialog.error(err.message);
  //       });
  // }
  // useEffect(() => {
  //   if(props.active && !init) {
  //     load();
  //   }
  // }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    let opacity;
    if (props.active) {
      if (scroll.top) {
        opacity = Math.abs(scroll.top) / 200;
        if (opacity > 1) {
          opacity = 1;
        }
      } else {
        opacity = 0;
      }
    }
    setOpacity(opacity);

    // if (props.active) {
    //   if (scroll.top < -80) {
    //     load();
    //   }
    // }
  }, [scroll, props.active]);

  //跳转
  const bindHred = (item) => {
    if(item.id == 'submit' || item.id == 'recovery') {
      if (!user.mobile) {
        Dialog.confirm({
          title: '没有绑定手机号，现在去绑定？',
          yes: () => {
            history.push('/user/phone');
          }
        });
      } else {
        history.push(item.url);
      }
    } else {
      history.push(item.url);
    }
  }

  useEffect(() => {
    if(props.active) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['BrowseAccountTransactionPage', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), agent: agent}]
      });
    }
  }, [props.active]);

  return <div className="trade-index">
    <div className="trade-head width">
      <div className="background" style={{opacity: opacity !== undefined ? opacity : 1}}/>
      <div className="container">
        账号交易
        <NavLink user={false} className="service" to="/service">
          <img className="icon" src="resources/index/index-service-1.png" alt="" />
        </NavLink>
      </div>
    </div>
    <div className="trade-menu trade-screen-menu">
      <div className="items">
      {menus.map(item => <NavLink user={item.user} className="item" onClick={()=>{bindHred(item)}}>
        <img src={'/resources/trade/' + item.id + '.png'} alt=""/>
        {item.text}

        {item.id == 'submit' ? 
        <div className="tips">
          {/* 卖闲置 */}
          <img src="/resources/trade/mxz.gif" />
        </div>
        : null}
      </NavLink>)}
      </div>
      {/* {menus.map(item => <NavLink to={item.url} user={item.user} className="item">
        <img src={'/resources/trade/' + item.id + '.png'} alt=""/>
        {item.text}
      </NavLink>)} */}
    </div>

    <div className="trade-body">
    {title_items?.length > 0 ? <div className="trade-header">
        <div className={index === 0 ? 'active' : ''} onClick={()=>{
          setIndex(0);
        }}>{title_items[0].name}</div>
        <div className={index === 1 ? 'active' : ''} onClick={()=>{
          setIndex(1);
        }}>{title_items[1].name}</div>
      </div> : null
      }
      <div>
        <div style={{display:index===1 ? 'none' : ''}}>
          <List active={index == 0 && props.active} good={false} source='' handleTitle={title=>setTitle_items(title)}/> 
        </div>
        <div style={{display:index===0 ? 'none' : ''}}>
          <List active={index == 1 && props.active} good={true} source='czjl' handleTitle={title=>setTitle_items(title)}/>
        </div>
      </div>
    </div>
    {/* <div className="trade-index-good">
        <div className="title">
          <div className="text">{titleInfo.title}<img src={titleInfo.image} alt=""/></div>
          <Link to="/trade/good?source=czjl" className="more">更多</Link>
        </div>
        {trades? 
        <div className="items">
          {trades.map(trade => <Link to={'/trade/detail?id=' + trade.trade_id} className="item">
            <div className="icon"><Image src={trade.game_icon}/></div>
            <div className="info">
              <div className="title">{trade.game_name}</div>
              <div className="name">#{trade.title}#</div>
              <div className="price"><span>￥</span>{trade.trade_price}</div>
            </div>
          </Link>)}
        </div>
        : null}
      </div>
      <List active={props.active}/> */}
  </div>;
};